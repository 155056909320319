import {CartOutletType} from 'stores';

type MenuItemType = CartOutletType['stores'][0]['menuItems'][0];

const itemsSimilar =
  (item: Pick<MenuItemType, 'menuItemId' | 'remark' | 'menuOptions'>) =>
  ({menuItemId, menuOptions, remark}: MenuItemType) => {
    if (menuItemId !== item.menuItemId) return false;
    if (remark !== item.remark) return false;
    const itemOptionIds = menuOptions.flatMap(({itemOptions}) => itemOptions.map(({itemOptionId}) => itemOptionId)).sort() || [];
    const ItemOptionIds = item.menuOptions.flatMap(({itemOptions}) => itemOptions.map(({itemOptionId}) => itemOptionId)).sort() || [];
    return JSON.stringify(itemOptionIds) === JSON.stringify(ItemOptionIds);
  };

export default itemsSimilar;
